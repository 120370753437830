let responseDetailMixin = {
  computed: {
    image() {
      if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
        let photo = this.dataInfo['photo']
        return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`
      }
      return this.$replacePhoto('')
    },
    rate1() {
      if (this.$valueIsExist(this.dataInfo, 'rate1') == false) {
        return -1
      }
      return parseInt(this.dataInfo['rate1'])
    },
    rate2() {
      if (this.$valueIsExist(this.dataInfo, 'rate2') == false) {
        return -1
      }
      return parseInt(this.dataInfo['rate2'])
    },
    rate3() {
      if (this.$valueIsExist(this.dataInfo, 'rate3') == false) {
        return -1
      }
      return parseInt(this.dataInfo['rate3'])
    },
    rate4() {
      if (this.$valueIsExist(this.dataInfo, 'rate4') == false) {
        return -1
      }
      return parseInt(this.dataInfo['rate4'])
    },
    rate5() {
      if (this.$valueIsExist(this.dataInfo, 'rate5') == false) {
        return -1
      }
      return parseInt(this.dataInfo['rate5'])
    },
    rate6() {
      if (this.$valueIsExist(this.dataInfo, 'rate6') == false) {
        return -1
      }
      return parseInt(this.dataInfo['rate6'])
    },
    comment2() {
      if (this.$valueIsExist(this.dataInfo, 'comment2') == false || this.dataInfo['comment2'] == '') {
        return '暂无评价'
      }
      return this.dataInfo['comment2']
    },
    comment1() {
      if (this.$valueIsExist(this.dataInfo, 'comment1') == false || this.dataInfo['comment1'] == '') {
        return '暂无评价'
      }
      return this.dataInfo['comment1']
    },
    photo1() {
      if (this.$valueIsExist(this.dataInfo, 'photos1') == false) {
        return []
      }
      return this.dataInfo['photos1']
    },
    photo2() {
      if (this.$valueIsExist(this.dataInfo, 'photos2') == false) {
        return []
      }
      return this.dataInfo['photos2']
    },
    photo4() {
      if (this.$valueIsExist(this.dataInfo, 'photos4') == false) {
        return []
      }
      return this.dataInfo['photos4']
    },
    photo5() {
      if (this.$valueIsExist(this.dataInfo, 'photos5') == false) {
        return []
      }
      return this.dataInfo['photos5']
    },
    photo6() {
      if (this.$valueIsExist(this.dataInfo, 'photos6') == false) {
        return []
      }
      return this.dataInfo['photos6']
    },
    repairResult() {
      if (this.$valueIsExist(this.dataInfo, 'repairResult') == false) {
        return ''
      }
      return this.dataInfo['repairResult']
    },
    reasonDetail() {
      if (this.$valueIsExist(this.dataInfo, 'detail') == false) {
        return ''
      }
      return this.dataInfo['detail']
    },
    severityTitle() {
      let severity = 0
      if (this.$valueIsExist(this.dataInfo, 'severity')) {
        severity = this.dataInfo['severity']
      }
      return this.getSeverityStatusTitle(severity)
    },
    deviceStatusTitle() {
      let deviceStatus = 0
      if (this.$valueIsExist(this.dataInfo, 'deviceStatus')) {
        deviceStatus = this.dataInfo['deviceStatus']
      }
      return this.getDeviceStatusTitle(deviceStatus)
    },

    repairDetail() {
      if (this.$valueIsExist(this.dataInfo, 'repairDetail') == false) {
        return ''
      }
      return this.dataInfo['repairDetail']
    },
    repairTypeTitle() {
      let repairType = 0
      if (this.$valueIsExist(this.dataInfo, 'repairType')) {
        repairType = this.dataInfo['repairType']
      }
      return this.getStatusTitle(repairType)
    },
    repairTime() {
      if (this.$valueIsExist(this.dataInfo, 'repairTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['repairTime'])
    },
    reportTime() {
      if (this.$valueIsExist(this.dataInfo, 'reportTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['reportTime'])
    },
    repairUser() {
      if (this.$valueIsExist(this.dataInfo, 'repairUser') == false) {
        return ''
      }
      return this.dataInfo['repairUser']
    },
    showRepairWorker() {
      if (this.status >= 5) {
        return true
      }
      return false
    },
    deviceLabel() {
      if (this.$valueIsExist(this.dataInfo, 'deviceLabel') == false) {
        return ''
      }
      return this.dataInfo['deviceLabel']
    },
    deviceCode() {
      if (this.$valueIsExist(this.dataInfo, 'deviceCode') == false) {
        return ''
      }
      return this.dataInfo['deviceCode']
    },
    tenantName() {
      if (this.$valueIsExist(this.dataInfo, 'tenantName') == false) {
        return ''
      }
      return this.dataInfo['tenantName']
    },
    categoryName() {
      if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
        return ''
      }
      return this.dataInfo['categoryName']
    },
    serialNumber() {
      if (this.$valueIsExist(this.dataInfo, 'serialNumber') == false) {
        return ''
      }
      return this.dataInfo['serialNumber']
    },
    address() {
      if (this.$valueIsExist(this.dataInfo, 'address') == false) {
        return ''
      }
      return this.dataInfo['address']
    },
    phone() {
      if (this.$valueIsExist(this.dataInfo, 'phone') == false) {
        return ''
      }
      return this.dataInfo['phone']
    },
    reportUser() {
      if (this.$valueIsExist(this.dataInfo, 'reportUser') == false) {
        return ''
      }
      return this.dataInfo['reportUser']
    },
    deviceName() {
      if (this.$valueIsExist(this.dataInfo, 'deviceName') == false) {
        return ''
      }
      return this.dataInfo['deviceName']
    },
    status() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return status
    },
    statusTitle() {
      let status = this.status
      return this.getStatus(status)
    },
  },

  mounted() {
    this.getDataInfo()
  },
  data() {
    return {
      dataInfo: {},
      dataList: []
    }
  },
  methods: {
    getDataInfo: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          this.$api.deviceRepair
            .reportDataInfo({
              id: id,
              photo: '1,2,3,4,5,6'
            }).then(res => {
            if (this.$valueIsExist(res, 'data') == false) {
              resolve({ result: false, data: {}, dataList: [] })
            }
            let report = {}
            if (this.$valueIsExist(res.data, 'report')) {
              report = res.data['report']
            }
            let dataList = []
            if (this.$valueIsExist(res.data, 'data')) {
              dataList = res.data['data']
            }
            resolve({ result: true, data: report, dataList: dataList })
          }).catch(e => {
            resolve({ result: false, data: {}, dataList: [] })
          })
        })
      }

      getData().then(res => {
        this.dataInfo = res.data
        this.dataList = res.dataList;
      })
    }
  }
}

export default responseDetailMixin