<template>
  <div class="responseDetail" :style="{ 'background-image': 'url(' + bgImg + ')' }">
    <div class="responseHeader">
      <div class="responseHeaderContent">
        <svg-icon class="responseHeaderIcon" size="16px" name="finished"></svg-icon>
        <div class="responseHeaderMsg">{{ deviceName }}</div>
        <div class="responseHeaderDetailMsg">
          该申请维修{{ statusTitle }}
        </div>
      </div>
    </div>

    <div class="responsePerson">
      <div class="responsePersonContentView">
        <div class="responsePersonMsg">{{ reportUser }} {{ phone }}</div>

        <div class="responsePersonAddress">
          地址:{{ address }}
        </div>
      </div>
    </div>


    <div class="responseDevice">
      <div class="responseDeviceContentView">
        <div class="responseDeviceHeader">
          {{ deviceName }}
        </div>

        <div class="responseContent">
          <img class="responseContentImg" :src="image">
          <div class="responseContentRight">
            <div class="titleHeader">
              {{ categoryName }}
            </div>

            <div class="content">
              <div class="header">设备标签</div>
              <div class="value">{{ deviceLabel }}</div>
              <div style="clear: both"></div>
            </div>

            <div class="content">
              <div class="header">设备科室</div>
              <div class="value">{{ tenantName }}</div>
              <div style="clear: both"></div>
            </div>

            <div class="content">
              <div class="header">{{$VUEAPPDEVICECODE()}}</div>
              <div class="value">{{ deviceCode }}</div>
              <div style="clear: both"></div>
            </div>


          </div>
          <div style="clear: both"></div>
        </div>


      </div>

    </div>

    <div class="responseBaseContentView">
      <div class="responseBaseContent">
        <div class="content">
          <div class="title">设备状态</div>
          <div class="value">{{ deviceStatusTitle }}</div>
          <div style="clear: both"></div>
        </div>
        <div class="content">
          <div class="title">紧急度</div>
          <div class="value danger">{{ severityTitle }}</div>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

    <div class="responseDescribe">
      <div class="responseDescribeContent">
        <div class="responseDescribeTitle">
          故障描述
        </div>
        <div class="responseDescribeDetail">
          故障现象:{{ reasonDetail }}
        </div>
        <van-grid v-if="photo1.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
                  :gutter="10"
        >
          <van-grid-item v-for="(item, index) in photo1" @click="showImageList(photo1, index)">
            <van-image :src="$replacePhoto(item.url)+'&scale=0.2'"/>
          </van-grid-item>
        </van-grid>
      </div>
    </div>


    <div class="responseBaseContentView" v-if="showRepairWorker">
      <div class="responseBaseContent">
        <div class="contentMsg">
          基本信息
        </div>
        <div class="content">
          <div class="title">报修人</div>
          <div class="value">{{ reportUser }}</div>
          <div style="clear: both"></div>
        </div>
        <div class="content">
          <div class="title">报修时间</div>
          <div class="value">{{ reportTime }}</div>
          <div style="clear: both"></div>
        </div>
        <div class="content">
          <div class="title">维修人</div>
          <div class="value">{{ repairUser }}</div>
          <div style="clear: both"></div>
        </div>
        <div class="content">
          <div class="title">维修时间</div>
          <div class="value">{{ repairTime }}</div>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

    <div class="responseBaseContentView" v-if="status>=7">
      <div class="responseBaseContent">
        <div class="contentMsg">
          维修内容
        </div>
        <div class="content">
          <div class="title">故障类型</div>
          <div class="value">{{ repairTypeTitle }}</div>
          <div style="clear: both"></div>
        </div>
        <div class="content">
          <div class="title">故障原因</div>
          <div class="value">{{ repairDetail }}</div>
          <div style="clear: both"></div>
        </div>
        <van-grid v-if="photo2.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
                  :gutter="10"
        >

          <van-grid-item v-for="(item, index) in photo2" @click="showImageList(photo2, index)">
            <van-image :src="$replacePhoto(item.url)+'&scale=0.2'"/>
          </van-grid-item>
        </van-grid>
      </div>
    </div>


    <div class="responseDescribe" v-if="status==11||status==12">
      <div class="responseDescribeContent">
        <div class="responseDescribeTitle">
          处理结果
        </div>

        <div class="responseDescribeDetail">
          {{ repairResult }}
        </div>
        <van-grid v-if="photo4.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
                  :gutter="10"
        >
          <van-grid-item v-for="(item, index) in photo4" @click="showImageList(photo4, index)">
            <van-image :src="$replacePhoto(item.url)+'&scale=0.2'"/>
          </van-grid-item>
        </van-grid>
      </div>
    </div>


    <div class="responseDescribe" v-if="status==11">
      <div class="responseDescribeContent">
        <div class="responseDescribeTitle">
          借用人评价
        </div>
        <div class="responseDescribeDetail">
          {{ comment2 }}
        </div>

        <div class="responseDescribeTitle" v-if="rate4>=0">
          对工程师到现场的响应速度满意度
        </div>

        <div class="responseRate" v-if="rate4>=0">
          <van-rate
              v-model="rate4"
              readonly
              :size="24"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
          />
        </div>

        <div class="responseDescribeTitle" v-if="rate5>=0">
          对工程师的技术水平和维修效率满意度
        </div>

        <div class="responseRate" v-if="rate5>=0">
          <van-rate
              v-model="rate5"
              readonly
              :size="24"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
          />
        </div>

        <div class="responseDescribeTitle" v-if="rate6>=0">
          对工程师维修结果满意度
        </div>

        <div class="responseRate" v-if="rate6>=0">
          <van-rate
              v-model="rate6"
              readonly
              :size="24"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
          />
        </div>

        <van-grid v-if="photo6.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
                  :gutter="10"
        >
          <van-grid-item v-for="(item, index) in photo6" @click="showImageList(photo6, index)">
            <van-image :src="$replacePhoto(item.url)+'&scale=0.2'"/>
          </van-grid-item>
        </van-grid>
      </div>
    </div>

    <div class="responseDescribe" v-if="status==11">
      <div class="responseDescribeContent">
        <div class="responseDescribeTitle">
          设备科评价
        </div>
        <div class="responseDescribeDetail">
          {{ comment1 }}
        </div>

        <div class="responseDescribeTitle" v-if="rate1>=0">
          对工程师到现场的响应速度满意度
        </div>

        <div class="responseRate" v-if="rate1>=0">
          <van-rate
              v-model="rate1"
              readonly
              :size="24"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
          />
        </div>

        <div class="responseDescribeTitle" v-if="rate2>=0">
          对工程师的技术水平和维修效率满意度
        </div>

        <div class="responseRate" v-if="rate2>=0">
          <van-rate
              v-model="rate2"
              readonly
              :size="24"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
          />
        </div>

        <div class="responseDescribeTitle" v-if="rate3>=0">
          对工程师维修结果满意度
        </div>

        <div class="responseRate" v-if="rate3>=0">
          <van-rate
              v-model="rate3"
              readonly
              :size="24"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
          />
        </div>

        <van-grid v-if="photo5.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
                  :gutter="10"
        >
          <van-grid-item v-for="(item, index) in photo5" @click="showImageList(photo5, index)">
            <van-image :src="$replacePhoto(item.url)+'&scale=0.2'"/>
          </van-grid-item>
        </van-grid>
      </div>
    </div>


    <div class="responseBaseContentView">
      <div class="responseBaseContent">
        <order-item v-for="(item, index) in dataList"
                    :key="index"
                    :dataInfo="item"
        ></order-item>
      </div>
    </div>

  </div>
</template>

<script>
import OrderItem from '@views/shareAndDispatch/device/deviceRepair/order/orderItem'

let bgImg = require('../../../../../../static/iconImage/operationCenter.png')
import { ImagePreview } from 'vant'
import responseDetailMixin from '@views/shareAndDispatch/deviceView/response/responseDetail/responseDetailMixin'
import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'
import deviceWorkerRepairMixin from '@components/deviceWorkerRepairOptions/deviceWorkerRepairOptions'
import severityOption from '@/mixins/severityOption/index.js'
import deviceStatusOption from '@/mixins/deviceStatusOption/index.js'

export default {
  name: 'responseDetail',
  components: {
    OrderItem,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  mixins: [responseDetailMixin, shareDeviceOrderStatus, deviceWorkerRepairMixin, severityOption, deviceStatusOption],
  data() {
    return {
      bgImg: bgImg
    }
  },
  methods: {
    showImageList: function(imagelist = [], index = 0) {
      let list = []
      for (let i = 0; i < imagelist.length; i++) {
        let data = imagelist[i]
        list.push(this.$replacePhoto(data.url))
      }
      if (list.length == 0) {
        return
      }
      ImagePreview(list, index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.responseDetail {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: scroll;

  .responseHeader {
    width: 100%;

    .responseHeaderContent {
      padding: 20px 10px 10px 30px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      .responseHeaderIcon {
        width: 16px;
        height: 16px;
      }

      .responseHeaderMsg {
        width: calc(100% - 20px);
        line-height: 16px;
        color: white;
        font-size: 16px;
        word-wrap: break-word;
        word-break: break-all;
        font-weight: 400;
      }

      .responseHeaderDetailMsg {
        padding-top: 8px;
        width: 100%;
        line-height: 12px;
        color: white;
        font-size: 12px;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }

  .responsePerson {
    padding: 0px 10px 10px 10px;
    width: 100%;

    .responsePersonContentView {
      width: 100%;
      border-radius: 6px;
      background-color: white;
      overflow: hidden;

      .responsePersonMsg {
        width: 100%;
        line-height: 14px;
        padding: 12px 12px 0px 12px;
        font-size: 14px;
        font-weight: 400;
      }

      .responsePersonAddress {
        width: 100%;
        line-height: 12px;
        padding: 6px 12px 12px 12px;
        font-size: 12px;
        word-wrap: break-word;
        word-break: break-all;
        color: $color3;
      }
    }
  }

  .responseDevice {
    padding: 0px 10px 10px 10px;
    width: 100%;

    .responseDeviceContentView {
      padding: 8px 12px 8px 12px;
      width: 100%;
      overflow: hidden;
      border-radius: 6px;
      background-color: white;

      .responseDeviceHeader {
        width: 100%;
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 16px;
      }

      .responseContent {
        width: 100%;

        .responseContentImg {
          width: 60px;
          height: 60px;
          float: left;
          border-radius: 5px;
          overflow: hidden;
        }

        .responseContentRight {
          width: calc(100% - 85px);
          float: right;

          .titleHeader {
            width: 100%;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 6px;
          }

          .content {
            width: 100%;
            margin-bottom: 4px;

            .header {
              width: 60px;
              height: 12px;
              line-height: 12px;
              font-size: 12px;
              color: $color3;
              float: left;
            }

            .value {
              width: calc(100% - 80px);
              line-height: 12px;
              font-size: 12px;
              color: $color3;
              word-wrap: break-word;
              word-break: break-all;
              float: right;
            }

          }


        }

      }

    }


  }

  .responseBaseContentView {
    width: 100%;
    padding: 0px 10px 10px 10px;

    .responseBaseContent {
      width: 100%;
      background-color: white;
      border-radius: 6px;
      overflow: hidden;
      padding: 12px 12px 12px 12px;
      .contentMsg {
        width: 100%;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
      }

      .content {
        width: 100%;

        &:not(:first-child) {
          margin-top: 24px;
        }

        .detailTitle {
          width: 100%;
          line-height: 14px;
          font-size: 14px;
          word-wrap: break-word;
          word-break: break-all;
        }


        .title {
          width: 100px;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          float: left;
          color: $color3;
        }

        .value {
          width: calc(100% - 110px);
          line-height: 14px;
          font-size: 14px;
          text-align: right;
          float: right;
          word-wrap: break-word;
          word-break: break-all;
        }

        .danger {
          color: red;
        }
      }
    }
  }


  .responseDescribe {
    width: 100%;
    padding: 0px 10px 10px 10px;


    .responseDescribeContent {
      width: 100%;
      background-color: white;
      border-radius: 6px;
      overflow: hidden;
      padding: 12px 12px 12px 12px;


      .responseDescribeTitle {
        width: 100%;
        line-height: 15px;
        font-size: 15px;
        font-weight: 400;
        padding: 5px 0px 0px 0px;
      }

      .responseDescribeDetail {
        width: 100%;
        line-height: 12px;
        font-size: 12px;
        word-wrap: break-word;
        word-break: break-all;
        padding: 5px 0px 0px 0px;
        color: $color3;
      }

      .responseRate {
        text-align: center;
        width: 100%;
        line-height: 13px;
        font-size: 13px;
        word-wrap: break-word;
        word-break: break-all;
        margin: 5px 0px 5px 0px;
      }

      .responseDescribeDetailImgList {
        width: 100%;
      }
    }
  }

}

</style>