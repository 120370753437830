let deviceStatusOptionMixin = {
    data() {
      return {
        deviceStatusOption: [
            {
              label: "运行",
              value: 1,
            },
            {
              label: "停机",
              value: 0,
            },
          ],
      }
    },
    methods: {
      getDeviceStatusTitle: function(status) {
        for (let i = 0; i < this.deviceStatusOption.length; i++) {
          let data = this.deviceStatusOption[i]
          if (data.value != status) {
            continue
          }
          return data.label
        }
        return ''
      }
    }
  }
  
  export default deviceStatusOptionMixin