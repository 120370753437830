<template>
  <div class="orderItem">
    <div class="step">
      <span
        class="textEllipsis"
        v-if="type == 'reportDataInfo'"
        :style="{ color: getStatusData(dataInfo.status).color }"
        >{{ getStatusData(dataInfo.status).name }}</span>
      <span
        class="textEllipsis"
        v-if="type == 'shareData'"
        :style="{ color: formatStatusData(dataInfo.status).color }"
        >{{ formatStatusData(dataInfo.status).label }}</span>
      <span class="time">{{ formatDateTime(dataInfo.time) }}</span>
    </div>
    <div class="tenant">{{ dataInfo.tenantName }} {{ dataInfo.user }}</div>
    <div class="text">{{ dataInfo.remark }}</div>
  </div>
</template>

<script>
import { formatStatusData } from "@/baseConfig/orderStatusOptions/index.js";
import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'
export default {
  mixins:[shareDeviceOrderStatus],
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "reportDataInfo",
    },
  },
  data() {
    return {
      formatStatusData: formatStatusData,
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.orderItem {
  min-height: 70px;
  margin-left: 14px;
  border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
  padding-left: 20px;
  position: relative;
  &::before {
    content: "";
    background: #3e73fb;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: -6.5px;
  }
  &:last-child {
    border-left: 0 none;
  }
  .step {
    display: flex;
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    span {
      display: inline-block;
      width: 50%;
    }
    .time {
      color: $color2;
      text-align: right;
    }
  }
  .tenant {
    font-size: 12px;
    color: $color1;
    font-weight: 500;
    margin-top: 4px;
  }
  .text {
    font-size: 12px;
    color: $color2;
    font-weight: 500;
    padding-bottom: 24px;
  }
}
</style>