let deviceWorkerRepairMixin = {
  data() {
    return {
      repairOptions: [
        { name: '零件丢失', type: 1 },
        { name: '零件老化', type: 2 },
        { name: '接触不良', type: 3 },
        { name: '仪器进水', type: 4 },
        { name: '线路短路', type: 5 },
        { name: '供电不足', type: 6 },
        { name: '其他', type: 7 }]
    }
  },
  methods: {
    getStatusTitle: function(status) {
      for (let i = 0; i < this.repairOptions.length; i++) {
        let data = this.repairOptions[i]
        if (data.type != status) {
          continue
        }
        return data.name
      }
      return ''
    }
  }
}

export default deviceWorkerRepairMixin