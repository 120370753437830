let severityOptionMixin = {
    data() {
      return {
        severityOption: [
          {
            label: "一般",
            value: 5,
          },
          {
            label: "急",
            value: 3,
          },
          {
            label: "紧急",
            value: 1,
          },
        ]
      }
    },
    methods: {
      getSeverityStatusTitle: function(status) {
        for (let i = 0; i < this.severityOption.length; i++) {
          let data = this.severityOption[i]
          if (data.value != status) {
            continue
          }
          return data.label
        }
        return ''
      }
    }
  }
  
  export default severityOptionMixin