let shareDeviceOrderStatus = {
  data() {
    return {
      severityOptions: [
        { name: '严重', type: 1, color: '#FF2222' },
        { name: '重要', type: 2, color: '#FF2222' },
        { name: '轻微', type: 3, color: '#FF2222' },
        { name: '警告', type: 4, color: '#FF2222' },
        { name: '一般', type: 5, color: '#999999' }
      ],
      workerRepairOptions: [
        { name: '报修', type: 1, color: '#FF2222' },
        { name: '待修', type: 2, color: '#FF2222' },
        { name: '催单', type: 3, color: '#FF2222' },
        { name: '响应催单', type: 4, color: '#FF2222' },
        { name: '未处理', type: 5, color: '#60DA47' },
        { name: '维修中', type: 6, color: '#FF2222' },
        { name: '维修完成', type: 7, color: '#60DA47' },
        { name: '审核通过', type: 8, color: '#60DA47' },
        { name: '拒绝报修', type: 9, color: '#60DA47' },
        { name: '维修驳回', type: 10, color: '#FF2222' },
        { name: '维修通过', type: 11, color: '#60DA47' },
        { name: '维修拒绝', type: 12, color: '#FF2222' },
        { name: '维修取消', type: 13, color: '#FF2222' },
        { name: '拒绝维修', type: 14, color: '#FF2222' },
        { name: '开始维修', type: 15, color: '#60DA47' },
        { name: '维修结算', type: 16, color: '#60DA47' }
      ],
      statusOptions: [
        { name: '报修', type: 1, color: '#FF2222' },
        { name: '待修', type: 2, color: '#FF2222' },
        { name: '催单', type: 3, color: '#FF2222' },
        { name: '响应催单', type: 4, color: '#FF2222' },
        { name: '已指派', type: 5, color: '#60DA47' },
        { name: '维修中', type: 6, color: '#FF2222' },
        { name: '维修完成', type: 7, color: '#60DA47' },
        { name: '审核通过', type: 8, color: '#60DA47' },
        { name: '拒绝报修', type: 9, color: '#FF2222' },
        { name: '维修驳回', type: 10, color: '#FF2222' },
        { name: '维修通过', type: 11, color: '#60DA47' },
        { name: '维修未通过', type: 12, color: '#FF2222' },
        { name: '维修取消', type: 13, color: '#FF2222' },
        { name: '拒绝维修', type: 14, color: '#FF2222' },
        { name: '开始维修', type: 15, color: '#60DA47' },
        { name: '维修结算', type: 16, color: '#60DA47' }
      ]
    }
  },
  methods: {
    getSeverityData(status) {
      for (let i = 0; i < this.severityOptions.length; i++) {
        let data = this.severityOptions[i]
        if (status != data.type) {
          continue
        }
        return data.name
      }
      return '-'
    },
    formatType(status) {
      for (let i = 0; i < this.severityOptions.length; i++) {
        let data = this.severityOptions[i]
        if (status != data.type) {
          continue
        }
        return `<div style="font-weight: 500;background:${data.color};border-radius: 4px;">${data.name}</div>`
      }
      return `<div style="font-weight: 500;background:${data.color};border-radius: 4px;">-</div>`
    },
    getStatus(status, list = this.statusOptions) {
      for (let i = 0; i < list.length; i++) {
        let data = list[i]
        if (status != data.type) {
          continue
        }
        return data.name
      }
      return ''
    },
    getStatusData(status, list = this.statusOptions) {
      for (let i = 0; i < list.length; i++) {
        let data = list[i]
        if (status != data.type) {
          continue
        }
        return data
      }
      return { name: '-', type: -1, color: '#FF2222' }
    },
    formatStatus(status) {
      for (let i = 0; i < this.statusOptions.length; i++) {
        let data = this.statusOptions[i]
        if (status != data.type) {
          continue
        }
        return `<div style="font-weight: 500;color:${data.color}">${data.name}</div>`
      }
      return `<div style="font-weight: 500;color:#FF2222;">-</div>`
    }
  }
}

export default shareDeviceOrderStatus